import { toHead, type ToMetaTagsType } from 'vue-datocms';

export function useSeo(
  datoMetaTags: {
    attributes?: any;
    content?: string | null | undefined;
    tag: string;
  }[],
) {
  const datoHead = toHead(datoMetaTags as ToMetaTagsType);
  const i18nHead = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true,
  });

  useHead({
    htmlAttrs: {
      lang: i18nHead.value.htmlAttrs!.lang,
    },
    title: datoHead.title ?? '',
    titleTemplate: '%s | m&m public design',
    link: [...(i18nHead.value.link || []), ...datoHead.link],
    meta: [...(i18nHead.value.meta || []), ...datoHead.meta],
  });
}
