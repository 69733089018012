type SiteHeaderBackground = 'opaque' | 'transparent';

export function useSiteHeaderBackground(
  background: SiteHeaderBackground,
  options?: { onVs?: SiteHeaderBackground; onVl?: SiteHeaderBackground },
) {
  const nuxtApp = useNuxtApp();

  const { isVs, isVl } = useViewportSize();

  onMounted(function () {
    if (!nuxtApp.$SiteHeader) {
      return;
    }

    if (options && options.onVl && isVl.value) {
      return nuxtApp.$SiteHeader.changeBackground(options.onVl);
    }

    if (options && options.onVs && isVs.value) {
      return nuxtApp.$SiteHeader.changeBackground(options.onVs);
    }

    return nuxtApp.$SiteHeader.changeBackground(background);
  });
}
